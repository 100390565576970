<template>
	<div>
		<Add
        v-if="showType != 'view'"
        v-model:addForm="addForm"
        v-model:hscode="hscode"
        v-model:hscodeTitle="hscodeTitle"
        v-model:associatedIndex="associatedIndex"
        :detailForm="detailForm"
    ></Add>
		<View v-else :detailForm="detailForm"></View>
	</div>
</template>

<script>
import Add from './baseinfo/Add.vue';
import View from './baseinfo/View.vue';
import _ from 'lodash';
export default {
	data() {
		return {
			addForm: {},
      hscode: null,
      hscodeTitle: null,
      associatedIndex:{}
		};
	},
	props: {
		showType: {
			type: String,
			default: ''
		},
		detailForm: {
			type: Object,
			default: () => {}
		},
		submitForm: {
			type: Object,
			default: () => {}
		},
        flag: {
            type: Number,
            default: 0
        }
	},
	emits: ['update:submitForm', 'update:hscode', 'update:hscodeTitle', 'update:associatedIndex'],
	watch: {
		addForm: {
			handler(val) {
				let submit = _.mergeWith(this.submitForm, val, (objV, srcV) => {
					return srcV;
				});
				this.$emit('update:submitForm', submit);
			},
			deep: true
		},
    hscode(val) {
      // console.log('baseinfo.hscode传递的:',val)
      this.$emit("update:hscode", val);
    },
    hscodeTitle(val) {
      // console.log('baseinfo.hscodeTitle传递的:',val)
      this.$emit("update:hscodeTitle", val);
    },
    associatedIndex: {
      handler(val) {
        // console.log('传递的associatedIndex:',val)
        this.$emit("update:associatedIndex", val);
      },
      deep: true,
    }
	},
	mounted() {},
	methods: {},
	components: {
		Add,
		View
	}
};
</script>

<style scoped lang="scss"></style>
