<template>
  <!-- 纱线 -->
  <div class="fabric">
    <label>{{ $t("yarn.name") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <div>
      <table class="table table-borderless">
        <thead class="table-light">
          <tr>
            <th
              style="width: 25%"
              v-for="(title, index) in yarnTitle"
              :key="index"
            >
              {{ title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(info, index) in yarnList" :key="index">
            <td class="table-index">#{{ index + 1 }}</td>
            <td class="table-id id">
              <a
                class="text-decoration-underline cursor"
                target="_blank"
                @click="jump2Yarn(info.id)"
                >{{ info.yarn_id }}</a
              >
            </td>
            <!-- <td>{{ info.yarn_id }}</td> -->
            <td
              class="table-persentage"
              v-if="info.persentage == undefined || info.persentage == ''"
            >
              {{ $t("base.none") }}
            </td>
            <td class="table-persentage" v-else>{{ info.persentage }}</td>
            <td class="table-type">{{ info.type }}</td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="yarnList == undefined || yarnList.length == 0"
        style="position: relative; height: 40px"
      >
        <Empty flag="2" :content="$t('base.none')"></Empty>
      </div>
    </div>
  </div>
  <!-- 纤维 -->
  <div class="fiber mt-5">
    <label>{{ $t("fiber.name") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <div>
      <table class="table table-borderless">
        <thead class="table-light">
          <tr>
            <th
              class="width: 25%"
              v-for="(title, index) in fiberTitle"
              :key="index"
            >
              {{ title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(info, index) in fiberList" :key="index">
            <td class="table-index">#{{ index + 1 }}</td>
            <td class="table-id id">
              <a
                class="text-decoration-underline cursor"
                target="_blank"
                @click="jump2Fibre(info.id)"
                >{{ info.fibre_id }}</a
              >
            </td>
            <td
              class="table-persentage"
              v-if="info.persentage == undefined || info.persentage == ''"
            >
              {{ $t("base.none") }}
            </td>
            <td class="table-persentage" v-else>{{ info.persentage }}</td>
            <td class="table-type">{{ info.type }}</td>
            <!-- <td>{{ info.supplier_name }}</td>
                        <td>{{ info.brand_name }}</td>
                        <td>{{ info.price }}</td>
                        <td>{{ info.diameter }}</td>
                        <td>{{ info.density }}</td> -->
          </tr>
        </tbody>
      </table>
      <div
        v-if="fiberList == undefined || fiberList.length == 0"
        style="position: relative; height: 40px"
      >
        <Empty flag="2" :content="$t('base.none')"></Empty>
      </div>
    </div>
  </div>
  <!-- 图表 -->
  <!-- <div><div :id="fabricChartId" style="width: 100%; height: 400px"></div></div> -->
</template>

<script>
import { sankeyChart, sankey1Chart } from "@/utils/chart.js";
import { getSankeyChartInfo } from "@/service";
import _ from "lodash";

export default {
  data() {
    return {
      yarnTitle: [
        this.$t("attribute.number"),
        this.$t("yarn.id"),
        this.$t("yarn.percentage") +
          " (" +
          this.$t("yarn.percentage_unit") +
          ")",
        this.$t("yarn.type"),
      ],
      yarnList: [],
      fiberTitle: [
        this.$t("attribute.number"),
        this.$t("fiber.id"),
        this.$t("fiber.percentage") +
          " (" +
          this.$t("fiber.percentage_unit") +
          ")",
        this.$t("fiber.type"),
        // this.$t('attribute.supplier'),
        // this.$t('attribute.brand'),
        // this.$t('attribute.price'),
        // this.$t('attribute.diameter'),
        // this.$t('attribute.density'),
      ],
      fiberList: [],
      fabricChartId: `fabric${+new Date()}`,
      pictures: [],
      showPreviewImg: false,
      src: "",
    };
  },
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.yarnList = _.get(val, "yarns", []);
          this.fiberList = _.get(val, "fibres", []);
          // this.requestSankeyChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    requestSankeyChart() {
      const params = {
        id: this.detailForm.id,
        key: this.detailForm.key,
      };
      getSankeyChartInfo(params)
        .then((res) => {
          this.setChart(res.payload);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setChart(data) {
      let options = {
        chartDom: document.querySelector(`#${this.fabricChartId}`),
        data: data,
      };
      sankey1Chart(options);
    },
    initChart() {
      let options = {
        chartDom: document.querySelector(`#${this.fabricChartId}`),
      };
      sankeyChart(options);
    },
    jump2Yarn(id) {
      if (id) {
        this.$router.push(`/yarn_detail/?id=${id}&type=view`);
      }
    },
    jump2Fibre(id) {
      if (id) {
        this.$router.push(`/fiber_detail/?id=${id}&type=view`);
      }
    },
    handleImgPreview(item) {
      this.src = item.url;
      if (this.src == undefined || this.src == null || this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
  },
};
</script>

<style scoped lang="less"></style>
<style>
.table-index {
  width: 25%;
}

.table-id {
  width: 25%;
}

.table-persentage {
  width: 25%;
}

.table-type {
  width: 25%;
}
</style>
